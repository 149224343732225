
.table-row > div {
    display: flex;
    align-items: center;
    padding: 8px;

    &:first-child {
        padding-left: 16px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        padding-right: 16px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.table-row:hover > div {
    background: darken(white, 2%);
}
