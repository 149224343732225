
.recommendation-acceptance {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    display: inline-block;
}

.recommendation-declined {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: inline-block;
}
