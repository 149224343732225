
.table-header > div {
    padding: 8px;
    background: darken(white, 8%);
    margin-bottom: 0.5em;

    &:first-child {
        padding-left: 16px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        padding-right: 16px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
